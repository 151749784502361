import { messages } from "components/I18/en";

const chatMessages = {
  ...messages,
  chat: "Chat",
  none: "none",

  // Analytic Navs
  analytics: "Analytics",
  chats: "chats",

  // Insights
  insights: "insights",

  // Footer
  switchToVoice: "switch to voice"
};

export default chatMessages;
