import "./scss/index.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import "typeface-poppins";
import theme from "theme";
import BaseCSS from "theme/baseCSS";

import Routes, { Props as RoutesProps } from "./routes";

import { Locale } from "./components/I18";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";
import { IntercomProvider } from "components/Intercom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthWrapper } from "components/AuthWrapper/AuthWrapper";
import { Provider as ConfigProvider } from "./components/Config";
import { HistoryType, createHistory } from "lib/historyFactory";
import { ThemeProvider as MuiProvider, createTheme } from "@mui/material";
export { HistoryType } from "lib/historyFactory";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  }
});

interface Props extends RoutesProps {
  locale: Locale;
  routesComponent?: typeof Routes;
  configProviderComponent?: typeof ConfigProvider;
}

const Component = ({
  history,
  routesComponent: Routes_ = Routes,
  configProviderComponent: ConfigProvider_ = ConfigProvider,
  ...routeProps
}: Props) => (
  <BrowserRouter>
    <AuthWrapper>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider_>
          <MuiProvider
            theme={createTheme({
              typography: {
                fontFamily: [
                  "Inter",
                  "-apple-system",
                  "BlinkMacSystemFont",
                  '"Segoe UI"',
                  "Roboto",
                  '"Helvetica Neue"',
                  "Arial",
                  "sans-serif",
                  '"Apple Color Emoji"',
                  '"Segoe UI Emoji"',
                  '"Segoe UI Symbol"'
                ].join(","),
                fontSize: 14
              }
            })}
          >
            <ThemeProvider theme={theme.main}>
              <BaseCSS />
              <IntercomProvider>
                <Routes_ {...routeProps} history={history} />
              </IntercomProvider>
            </ThemeProvider>
          </MuiProvider>
        </ConfigProvider_>
      </QueryClientProvider>
    </AuthWrapper>
  </BrowserRouter>
);

interface RenderProps extends Pick<Props, "locale"> {
  readonly state?: string;
  readonly historyType: HistoryType;
  readonly historyFactory?: typeof createHistory;
}

export const VoiceApp = ({
  state,
  historyType,
  historyFactory = createHistory,
  ...props
}: RenderProps) => {
  const history = historyFactory(historyType);

  return <Component {...props} history={history} />;
};
