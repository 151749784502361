import { parse, stringify } from "qs";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useConfig } from "../components/Config";
import { validateUrlParams } from "./parseQuery";
import { History, createHashHistory } from "history";
import { LocationWithQuery } from "typescript/history";
import { IntercomEventData } from "../typescript/interfaces";

export enum HistoryType {
  HASH = "hash"
}

const history = createHashHistory();

export const useTrackEvent = () => {
  const { trackEvent } = useIntercom();
  const { uIConfigAnalytics } = useConfig();

  if (uIConfigAnalytics.analyticsType === "Intercom") {
    return ({ event, metaData }: IntercomEventData) =>
      trackEvent(event, metaData);
  }
  return () => {};
};

const functions = {
  history,
  createHistory(type: HistoryType): History {
    switch (type) {
      case HistoryType.HASH:
      default:
        return this.history;
    }
  }
};

export const createHistory = functions.createHistory.bind(functions);

/**
 * Grabs the parameters that have been pushed to the URL
 */
export const useUrlParams = () => {
  const {
    location: { search }
  } = useHistory();
  const parsedObj =
    parse(search, {
      ignoreQueryPrefix: true,
      arrayLimit: 65535
    }) || {};

  return validateUrlParams(parsedObj);
};

/**
 * Pushes parameters to the URL
 * Mainly used for filters
 */
export const useSetLocation = () => {
  const { location, push } = useHistory();
  return (l: Partial<LocationWithQuery>) =>
    push({
      pathname: l.pathname || location.pathname,
      search: l.query ? stringify(l.query) : location.search
    });
};
