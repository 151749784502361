import { PropsWithChildren } from "react";
import { useConfig } from "../Config";
import { IntercomProvider as Provider } from "react-use-intercom";

export const IntercomProvider = ({ children }: PropsWithChildren<{}>) => {
  const config = useConfig();
  return (
    <Provider
      appId={
        config.uIConfigAnalytics.analyticsType == "Intercom"
          ? config.uIConfigAnalytics.analyticsAppId || ""
          : ""
      }
    >
      {children}
    </Provider>
  );
};
