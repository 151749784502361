export const DIRECTION = ["inbound", "outbound"] as const;
export const SPEAKER = ["agent", "caller"] as const;
export const RISK = ["highRisk", "notHighRisk"] as const;
export const REVIEW_STATUS = ["REQUIRES_ACTION", "COMPLETE"] as const;
export const SCORE = ["good", "bad", "neutral"] as const;
export const SORT_DIRECTION = ["asc", "desc"] as const;
export const PROCRESSING_STATUS = ["processing", "failed", "done"] as const;
export const EXCLUSION_SCORECARD = ["applied", "not_applied"] as const;
export const OVERRIDDEN_SCORECARD = ["applied", "not_applied"] as const;
export const SORT_BY = [
  "dateRecorded",
  "idCall",
  "agent",
  "duration",
  "communication",
  "compliance",
  "conduct",
  "qaScore",
  "status",
  "processStart",
  "processEnd",
  "callSize",
  "added",
  "searchResults",
  "highRiskCallCount",
  "callCount"
] as const;

export const filterOptions = [
  "direction",
  "agents",
  "risk",
  "questions",
  "teams",
  "speaker",
  "reviewStatus",
  "layer",
  "customData",
  "callLabels",
  "silences",
  "scores",
  "callLength",
  "exclusionReasons",
  "callDrivers",
  "processingStatus",
  "exclusionScorecard",
  "overriddenScorecard"
] as const;

export const urlParamsKeys = [
  ...filterOptions,
  "pageSize",
  "page",
  "dir",
  "sort",
  "defaultSort",
  "startDate",
  "endDate",
  "configId",
  "query",
  "processingStatus",
  "exclusionScorecard",
  "overriddenScorecard"
] as const;

export const questionKeys = ["text", "scoreType"] as const;
export const silenceKeys = [
  "totalSilence",
  "longestSilence",
  "percentageSilence"
] as const;
export const silenceValueKeys = ["id", "upper", "lower"] as const;
export const callLengthKeys = ["upper", "lower"] as const;
export const defaultSortKeys = ["id", "dir"] as const;
export const scoreKeys = [
  "scoreOverall",
  "scoreConduct",
  "scoreCommunication",
  "scoreCompliance"
] as const;
export const scoreValueKeys = ["id", "upper", "lower"] as const;
