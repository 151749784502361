export default {
  search: "Search",
  search_calls: "Search by Call ID",
  search_phoneNo: "Search by Phone Number",
  search_agents: "Search {speaker}s",
  search_cases: "Search by Case ID",
  search_customers: "Search by Customers ID",
  callId: "Lisa Call ID: {callId}",
  externalReference: "External Call ID: {externalReference}",
  agentTitle: "{speaker}: {agentName}",
  dateTitle: "Date Recorded:",
  agentName: "{agentName}",
  notFound: "No results found",
  noAgentFound: "No {speaker}s found",
  noCallFound: "No calls found",
  invalidCallId: "Please provide a valid Call ID",
  invalidCaseId: "Please provide a valid Case ID",
  invalidCustomerId: "Please provide a valid Customer ID",
  placeholder: "Press ENTER to search for {value}",
  pressEnterToSearch: "Press ENTER to search"
};
