import styled from "styled-components/macro";

import { useAuth0 } from "@auth0/auth0-react";
import { useConfig } from "components/Config";
import { useIntercom } from "react-use-intercom";
import { PropsWithChildren, useEffect } from "react";

/**
 * Intercom only supports string/number/boolean values for custom attributes,
 * so we need to convert the list of roles to boolean flags.
 */
const spreadRoles = (roles: string[]) => {
  let result: Record<string, boolean> = {};

  roles.forEach(role => {
    // hasClientAgentRole, hasClientUserRole, hasClientAdminRole, etc...
    result["has" + role + "Role"] = true;
  });
  return result;
};

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const { boot } = useIntercom();
  const { user } = useAuth0();
  const { uIConfigAnalytics } = useConfig();
  const withIntercom = uIConfigAnalytics.analyticsType === "Intercom";

  const { origin } = window.location;

  useEffect(() => {
    if (user && withIntercom && uIConfigAnalytics.analyticsSiteId) {
      const companyName =
        user["https://daisee.com/app_metadata"]?.organization?.display_name ??
        "Unknown";
      const userRoles = user["https://daisee.com/app_metadata"]?.roles ?? [];
      const intercomUserHash = user["https://daisee.com/intercom_hash"];
      boot({
        customLauncherSelector: "#daisee-intercom",
        email: user.email,
        name: user.name,
        userHash: intercomUserHash,
        hideDefaultLauncher: true,
        company: {
          name: companyName,
          companyId: uIConfigAnalytics.analyticsSiteId,
          website: origin
        },
        customAttributes: {
          ...spreadRoles(userRoles)
        }
      });
    }
  }, [user?.name, withIntercom, uIConfigAnalytics.analyticsSiteId]);

  return <Page>{children}</Page>;
};

export default Layout;

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: auto;
  align-content: stretch;
  color: ${({ theme }) => theme.colours.text};
`;
