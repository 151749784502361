import * as serviceWorker from "./serviceWorker";
import { VoiceApp, HistoryType } from "./App";
import { createRoot } from "react-dom/client";

const element = document.getElementById("root");

const root = createRoot(element!);
root.render(
  <VoiceApp historyType={HistoryType.HASH} locale={navigator.language} />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
