import { IntlProvider } from "react-intl";

import { useContext, createContext, PropsWithChildren } from "react";

export type Locale = string;
export enum Language {
  EN
}

export type Messages = Record<string, string>;

export const language = (locale: Locale): Language => {
  switch (locale.toLowerCase().split("-", 2)[0]) {
    case "en":
    default:
      return Language.EN;
  }
};

interface ContextProps {
  locale: Locale;
  setLocale: (locale: Locale) => any;
}

export const Context: React.Context<ContextProps> = createContext({
  locale: "en-AU",
  setLocale: (_: Locale) => {}
});

interface ConsumerProps extends PropsWithChildren<{}> {
  messages: Messages;
}

const I18 = ({ children, messages }: ConsumerProps) => {
  const { locale } = useContext(Context);
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default I18;
