import { createGlobalStyle } from "styled-components/macro";

const BaseCSS = createGlobalStyle`
  :root {
    color: ${props => props.theme.colours.text};
  }

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
  }
`;

export default BaseCSS;
