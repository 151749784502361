import colours from "./colours";

import { DefaultTheme } from "styled-components/macro";

const fonts = {
  main: "Inter",
  secondary: "sans-serif"
};

const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1.0625rem",
  lg: "1.4375rem",
  xl: "1.6875rem",
  xxl: "2.0625rem"
};

const fontWeight = {
  title: 300,
  normal: 400,
  large: 600,
  subheading: 700
};

// 1rem equals 16px
const spacing = {
  none: "0",
  xxs: "0.25rem",
  xs: "0.5rem",
  sm: "1rem",
  md1: "1.25rem",
  md2: "1.5rem",
  lg: "2rem",
  xl: "4rem",
  xxl: "8rem"
};

const breakpoints = {
  mobile: "@media (max-width: 28.125em)",
  lgMobile: "@media (max-width: 39em)",
  tablet: "@media (max-width: 48em)",
  lgTablet: "@media (max-width: 55em)",
  laptop: "@media (max-width: 62.5em)",
  wide: "@media (max-width: 87.5em)"
};

const easings = {
  main: "cubic-bezier(0.87, 0, 0.13, 1)"
};

const iconSize = {
  sm: "1.5rem",
  md: "3rem",
  lg: "5rem"
};

const boxShadow = {
  default: `0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12)`,
  hover: `0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12)`,
  toggleHover: `0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08)`,
  card: "0 1px 15px 0 rgba(123, 123, 123, 0.05)",
  modal: "0 19px 38px rgba(0,0,0,0.20), 0 15px 12px rgba(0,0,0,0.15)",
  subtle: "0 1px 3px rgba(0,0,0,0.04), 0 1px 2px rgba(0,0,0,0.06)"
};

const zIndex = {
  page: 1,
  modal: 100,
  sidebar: 50
};

interface Themes {
  main: DefaultTheme;
}

const theme: Themes = {
  main: {
    fonts,
    fontSizes,
    fontWeight,
    colours,
    spacing,
    breakpoints,
    easings,
    iconSize,
    boxShadow,
    zIndex
  }
};

export default theme;
