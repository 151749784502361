import { ParsedQs } from "qs";
import { uniqBy } from "lodash";
import { DefaultSort } from "components/Table";
import { CallLength } from "Voice/components/Filter/CallLengthFilter";
import { ActiveRule as Question } from "Voice/components/Filter/QuestionFilter/QuestionFilter";

import {
  DIRECTION,
  SPEAKER,
  RISK,
  REVIEW_STATUS,
  SCORE,
  SORT_BY,
  SORT_DIRECTION,
  silenceKeys,
  questionKeys,
  defaultSortKeys,
  callLengthKeys,
  silenceValueKeys,
  PROCRESSING_STATUS,
  EXCLUSION_SCORECARD,
  scoreValueKeys,
  scoreKeys,
  OVERRIDDEN_SCORECARD
} from "../constants";

import {
  SortDirection,
  Sort,
  CallDirection,
  Speaker,
  ReviewStatus,
  SilenceKey,
  QuestionKey,
  DefaultSortKey,
  UrlParamsKey,
  CallLengthKey,
  SilenceValueKey,
  ExclusionScorecard,
  OverriddenScorecard,
  ScoreValueKey
} from "../typescript/types";
import { CallStatus } from "@daiseeai/call-backend-types";
import { Silence, Sliders } from "./silences";
import { Score, ScoreType } from "./scores";

export interface UrlParams {
  // Filters
  silences?: Record<Sliders, Silence>;
  callLength?: CallLength;
  direction?: CallDirection;
  speaker?: Speaker;
  risk?: string;
  reviewStatus?: ReviewStatus;
  questions?: Question[];
  callLabels?: string[];
  customData?: [string, string[]][];
  agents?: string[];
  teams?: string[];
  layer?: string;
  exclusionReasons?: string[];
  overriddenScorecard?: OverriddenScorecard;
  callDrivers?: string[];
  processingStatus?: CallStatus;
  exclusionScorecard?: ExclusionScorecard;

  // Table Params
  pageSize?: string;
  page?: string;
  dir?: SortDirection;
  sort?: Sort;
  defaultSort?: DefaultSort;

  // Dates
  startDate?: string;
  endDate?: string;

  // Scorecard Builder
  configId?: string;

  // Used to maintain the query for `call_navigation` endpoint on transcript search.
  query?: string;

  // Base Query (used to maintain the original search)
  baseQuery?: string;

  // Scores
  scores?: Record<ScoreType, Score>;

  // Used to maintain if we are searching within an agent
  agentId?: string;
}

export const isStringValidator = (v: unknown) =>
  typeof v === "string" && v !== "";
export const isNullableStringValidator = (v: unknown) => typeof v === "string";
export const isStringArrayValidator = (v: unknown) =>
  Array.isArray(v) && v.every(isStringValidator);
export const isNullableStringArrayValidator = (v: unknown) =>
  Array.isArray(v) && v.every(isNullableStringValidator);

export const isCustomDataFilterValidator = (v: unknown) =>
  Array.isArray(v) &&
  v.length == 2 &&
  isStringValidator(v[0]) &&
  isNullableStringArrayValidator(v[1]);

export const isArrayOfCustomDataFilterValidator = (v: unknown) =>
  Array.isArray(v) && v.every(isCustomDataFilterValidator);

/**
 * Accepts `v` and `options` array, and checks if `v` is a `string` & exists in given array.
 * Will validate a typed string literal object key.
 *
 * <br> E.G for silences filters `id`
 *
 * <br> Validates `v` as either `totalSilence` | `percentageSilence` | `longestSilence`
 */
const isKeyValid = (v: unknown, options: ReadonlyArray<unknown>) =>
  typeof v === "string" && options.includes(v);

const silenceValuesParser: Record<SilenceValueKey, (_: unknown) => boolean> = {
  id: isStringValidator,
  upper: isStringValidator,
  lower: isStringValidator
};

const scoreValueParser: Record<ScoreValueKey, (_: unknown) => boolean> = {
  id: isStringValidator,
  upper: isStringValidator,
  lower: isStringValidator
};

const silenceParser: Record<SilenceKey, (_: unknown) => boolean> = {
  totalSilence: x => objectValidator(x, silenceValueKeys, silenceValuesParser),
  longestSilence: x =>
    objectValidator(x, silenceValueKeys, silenceValuesParser),
  percentageSilence: x =>
    objectValidator(x, silenceValueKeys, silenceValuesParser)
};

const scoreParser: Record<ScoreType, (_: unknown) => boolean> = {
  scoreOverall: x => objectValidator(x, scoreValueKeys, scoreValueParser),
  scoreCommunication: x => objectValidator(x, scoreValueKeys, scoreValueParser),
  scoreCompliance: x => objectValidator(x, scoreValueKeys, scoreValueParser),
  scoreConduct: x => objectValidator(x, scoreValueKeys, scoreValueParser)
};
const callLengthParser: Record<CallLengthKey, (_: unknown) => boolean> = {
  upper: isStringValidator,
  lower: isStringValidator
};

const questionParser: Record<QuestionKey, (_: unknown) => boolean> = {
  text: isStringValidator,
  scoreType: x => isKeyValid(x, SCORE)
};

const defaultSortParser: Record<DefaultSortKey, (_: unknown) => boolean> = {
  id: x => isKeyValid(x, SORT_BY),
  dir: x => isKeyValid(x, SORT_DIRECTION)
};

/**
 * Use to ensure all objects in an array are unique based on the
 * specified key
 * <br>
 * e.g Scorecard Questions `text` key value pair
 */
const uniqueKeyValueArrayOfObjectsValidator = (
  v: unknown,
  keys: ReadonlyArray<unknown>,
  parser: Record<string, (_: unknown) => boolean>,
  checkUniqueKey: string
) => {
  if (v && Array.isArray(v) && uniqBy(v, checkUniqueKey).length === v.length) {
    return v.map(obj =>
      Object.entries(obj).every(param => {
        const [key, value] = param;
        const checkKey = keys.includes(key);
        return checkKey && parser[key](value);
      })
    );
  }
  return false;
};

const objectValidator = (
  v: unknown,
  keys: ReadonlyArray<unknown>,
  parser: Record<string, (_: unknown) => boolean>
) => {
  if (v && typeof v === "object") {
    return Object.entries(v).every(param => {
      const [key, value] = param;

      const checkKey = keys.includes(key);
      return checkKey && parser[key](value);
    });
  }
  return false;
};

const parsers: Record<string, (x: unknown) => boolean | boolean[]> = {
  // Filters
  silences: x => objectValidator(x, silenceKeys, silenceParser),
  callLength: x => objectValidator(x, callLengthKeys, callLengthParser),
  direction: x => isKeyValid(x, DIRECTION),
  speaker: x => isKeyValid(x, SPEAKER),
  risk: x => isKeyValid(x, RISK),
  reviewStatus: x => isKeyValid(x, REVIEW_STATUS),
  questions: x =>
    uniqueKeyValueArrayOfObjectsValidator(
      x,
      questionKeys,
      questionParser,
      "text"
    ),
  callLabels: isStringArrayValidator,
  customData: isArrayOfCustomDataFilterValidator,
  agents: isStringArrayValidator,
  teams: isStringArrayValidator,
  layer: isStringValidator,
  exclusionReasons: isStringArrayValidator,
  callDrivers: isStringArrayValidator,
  processingStatus: x => isKeyValid(x, PROCRESSING_STATUS),
  exclusionScorecard: x => isKeyValid(x, EXCLUSION_SCORECARD),
  overriddenScorecard: x => isKeyValid(x, OVERRIDDEN_SCORECARD),

  // Tables
  pageSize: isStringValidator,
  page: isStringValidator,
  dir: x => isKeyValid(x, SORT_DIRECTION),
  sort: x => isKeyValid(x, SORT_BY),
  defaultSort: x => objectValidator(x, defaultSortKeys, defaultSortParser),

  // Dates
  startDate: isStringValidator,
  endDate: isStringValidator,

  // Scorecard Builder
  configId: isStringValidator,

  // Search
  query: isStringValidator,

  baseQuery: isStringValidator,

  // Scores
  scores: x => objectValidator(x, scoreKeys, scoreParser),

  // AgentID we are searching within
  agentId: isStringValidator
};

export const validateUrlParams = (parsedObj: ParsedQs): UrlParams => {
  return Object.assign(
    {},
    ...Object.entries(parsedObj).map(param => {
      const [key, value] = param;
      if (key in parsers) {
        if (Array.isArray(value)) {
          const predicates = parsers[key as UrlParamsKey](value);
          const validParams = Array(...value).filter((_: unknown, i: number) =>
            Array.isArray(predicates) ? predicates[i] : predicates
          );
          return validParams.length ? { [key]: validParams } : {};
        } else if (parsers[key as UrlParamsKey](value)) {
          return { [key]: value };
        } else {
          return {};
        }
      } else {
        return {};
      }
    })
  );
};
