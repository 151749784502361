import I18, { Context, language, Language, Messages } from "../components/I18";

import Loading from "components/Loader/Spinner";

import { UIConfig } from "@daiseeai/call-backend-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, Fragment, Suspense } from "react";

import {
  Switch,
  Redirect,
  RouteProps,
  useLocation,
  useRouteMatch,
  Route as Route_,
  RouteComponentProps
} from "react-router-dom";

import {
  agentRole,
  clientUser,
  clientCoach,
  clientAdminRole,
  daiseeEmployeeRole
} from "lib/visibility";

type MessagesLoaders = Record<Language, Messages>;

export interface BaseRoute extends RouteProps {
  name: string;
  path: string;
  messages: MessagesLoaders;
  redirectFrom?: string[];
}

export interface Route extends BaseRoute {
  disabled?: boolean;
  searchKeys?: string[];
  visibilityPredicate?: (c: UIConfig) => boolean;
  getValues?: (config: UIConfig) => Record<string, string>;
}

interface AuthRedirect {
  to: string;
  conditional: () => boolean;
}

interface CustomProps {
  routes: Route[];
  authRedirects: AuthRedirect[];
}

interface LoadComponentProps {
  props: RouteComponentProps<{
    [x: string]: string | undefined;
  }>;
  route: Route;
  messages: Messages;
}

const LoadComponent = ({ route, messages, props }: LoadComponentProps) => {
  return <I18 messages={messages}>{route.render && route.render(props)}</I18>;
};

const useQueryParams = () => new URLSearchParams(useLocation().search);

export const NestedSwitch = ({ routes, authRedirects }: CustomProps) => {
  const { locale } = useContext(Context);
  const { path } = useRouteMatch();
  let { pathname } = useLocation();
  const lang = language(locale);

  return (
    <Switch>
      <Suspense fallback={<Loading />}>
        {authRedirects.map(
          ({ conditional, to }) =>
            conditional() && <Redirect key={to} to={to} />
        )}
        {routes.map(route => (
          <Route_
            exact
            strict
            sensitive
            key={route.path}
            path={path + route.path}
            render={props => (
              <LoadComponent
                props={props}
                route={route}
                messages={route.messages[lang]}
              />
            )}
          />
        ))}
        {routes
          .filter(({ redirectFrom }) => redirectFrom)
          .map(route => (
            <Fragment key={path}>
              {route.redirectFrom?.map(
                from =>
                  pathname === from && (
                    <Route_
                      key={path}
                      path={from}
                      render={() => <Redirect to={path + route.path} />}
                    />
                  )
              )}
            </Fragment>
          ))}
      </Suspense>
    </Switch>
  );
};

interface Props {
  routes: BaseRoute[];
}

/**
 * This is the base switch, it will divert traffic to the nested routes
 * of each product (Voice and Agent).
 */
const MainSwitch = ({ routes }: Props) => {
  const { user, isAuthenticated, isLoading, error, loginWithRedirect } =
    useAuth0();
  const queryParams = useQueryParams();
  const { locale } = useContext(Context);
  const lang = language(locale);
  const userRoles: string[] | undefined =
    user?.["https://daisee.com/app_metadata"]?.roles ?? [];

  // Redirect users to the correct product
  const UserRedirect = () => {
    if (isLoading) {
      return <Loading />;
    } else if (error) {
      return (
        <Route_
          exact
          path="/"
          render={() => (
            <Redirect to={{ pathname: "/authError", state: error }} />
          )}
        />
      );
    } else if (!isAuthenticated && !user) {
      // Loading has finished and we're not (yet) authenticated
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin,
          // We need to pass these params if the user has clicked an invitation link
          invitation: queryParams.get("invitation") || undefined,
          organization: queryParams.get("organization") || undefined
        }
      });
      return <Loading />;
    } else {
      // isLoading = false, error = false, (isAuthenticated || user) = true
      switch (true) {
        case userRoles?.includes(clientUser) ||
          userRoles?.includes(clientCoach) ||
          userRoles?.includes(clientAdminRole) ||
          userRoles?.includes(daiseeEmployeeRole):
          return (
            <Route_ path="/" exact render={() => <Redirect to="/voice" />} />
          );

        case userRoles?.includes(agentRole):
          return (
            <Route_
              exact
              path="/"
              render={() => <Redirect to="/agentView" />}
            />
          );

        default:
          return (
            <Route_ exact path="/" render={() => <Redirect to="/noroles" />} />
          );
      }
    }
  };

  return (
    <Switch>
      <Suspense fallback={<Loading />}>
        {routes.map(route => (
          <Route_
            key={route.path}
            path={route.path}
            render={props => (
              <LoadComponent
                props={props}
                route={route}
                messages={route.messages[lang]}
              />
            )}
          />
        ))}
        <UserRedirect />
      </Suspense>
    </Switch>
  );
};

export default MainSwitch;
