import Types from "@daiseeai/call-backend-types";

import { Endpoint } from "../generated/tsdefApi";
import {
  PipelineConfigInsertInV2,
  ScorecardBuilderImport
} from "typescript/endpoints";

// Agent profile endpoints

export const agent_summary: Endpoint<
  Types.AgentSummaryIn,
  Types.AgentSummaryOut
> = {
  url: "api/call/v2/agent_summary",
  method: "POST"
};

export const agent_scorecard_performance: Endpoint<
  Types.FiltersIn,
  Types.AgentScorecardPerformance[]
> = {
  url: "api/call/v2/agent_scorecard_performance",
  method: "POST"
};

export const agent_stats: Endpoint<Types.FiltersIn, Types.AgentStats[]> = {
  url: "api/call/v2/agent_stats",
  method: "POST"
};

export const agent_category_scores: Endpoint<
  Types.FiltersIn,
  Types.AgentCategoryScoresOut[]
> = {
  url: "api/call/v2/agent_category_scores",
  method: "POST"
};

export const agents: Endpoint<Types.AgentListIn, Types.AgentDetail[]> = {
  url: "api/call/v2/agents",
  method: "POST"
};

export const calls: Endpoint<Types.FiltersIn, Types.Calls> = {
  url: "api/call/v2/calls",
  method: "POST"
};

export const call_delete: Endpoint<Types.CallDeleteIn, undefined> = {
  url: "api/call/v2/call_delete",
  method: "POST"
};

export const calls_report: Endpoint<Types.FiltersIn, Blob> = {
  url: "api/call/v2/calls_report",
  method: "POST"
};

// Call label endpoints

export const call_label_delete: Endpoint<Types.CallLabelDeleteIn, undefined> = {
  url: "api/call/v2/call_label_delete",
  method: "POST"
};

export const call_label_insert: Endpoint<Types.CallLabelInsertIn, undefined> = {
  url: "api/call/v2/call_label_insert",
  method: "POST"
};

export const call_label_update: Endpoint<Types.CallLabelUpdateIn, undefined> = {
  url: "api/call/v2/call_label_update",
  method: "POST"
};

export const call_label_tag_add: Endpoint<Types.CallLabelTagIn, undefined> = {
  url: "api/call/v2/call_label_tag_add",
  method: "POST"
};

export const call_label_tag_remove: Endpoint<Types.CallLabelTagIn, undefined> =
  {
    url: "api/call/v2/call_label_tag_remove",
    method: "POST"
  };

export const call_labels: Endpoint<{}, Types.CallLabel[]> = {
  url: "api/call/v2/call_labels",
  method: "GET"
};

// Call player endpoints

export const call_player: Endpoint<Types.CallPlayerIn, Types.CallPlayer> = {
  url: "api/call/v2/call_player",
  method: "POST"
};

// Audio Endpoint for call player

export const audio_url: Endpoint<{}, string> = {
  url: "api/call/v2/audio_url",
  method: "GET"
};

export const audio_full: Endpoint<{}, File> = {
  url: "api/call/v2/audio_full",
  method: "GET"
};

export const grouped_calls: Endpoint<Types.CallPlayerIn, Types.GroupedCall[]> =
  {
    url: "api/call/v2/grouped_calls",
    method: "POST"
  };

// Call processing endpoint

export const call_processing: Endpoint<
  Types.CallProcessingIn,
  Types.CallProcessingOut
> = {
  url: "api/call/v2/call_processing",
  method: "POST"
};

// Transcript search

export const call_transcript_search: Endpoint<
  Types.CallTranscriptSearchIn,
  Types.CallTranscriptSearchResult[]
> = {
  url: "api/call/v2/call_transcript_search",
  method: "POST"
};

// Custom metadata endpoints

export const custom_metadata_keys: Endpoint<{}, Types.CustomMetadataKeys> = {
  url: "api/call/v2/custom_metadata_keys",
  method: "GET"
};

export const custom_metadata_values: Endpoint<
  Types.CustomMetadataKeyIn,
  Types.CustomMetadataValues
> = {
  url: "api/call/v2/custom_metadata_values",
  method: "POST"
};

export const essence_labels: Endpoint<{}, Types.EssenceLabel[]> = {
  url: "api/call/v2/essence_labels",
  method: "GET"
};

export const essence_call_label: Endpoint<
  Types.EssenceCallLabelIn,
  Types.EssenceLabel
> = {
  url: "api/call/v2/essence_call_label",
  method: "POST"
};

// Manual scorecard endpoints

export const form_submission_get: Endpoint<
  Types.FormSubmissionIn,
  Types.FormSubmissionData
> = {
  url: "api/call/v2/manual_scorecard_filled_get",
  method: "POST"
};

export const form_submission_upsert: Endpoint<
  Types.ManualScorecardFilledUpsertIn,
  {}
> = {
  url: "api/call/v2/manual_scorecard_filled_upsert",
  method: "POST"
};

export const form_submission_delete: Endpoint<
  Types.ManualScorecardFilledDeleteIn,
  {}
> = {
  url: "api/call/v2/manual_scorecard_filled_delete",
  method: "POST"
};

export const manual_scorecard_names: Endpoint<
  {},
  Types.ManualScorecardNames[]
> = {
  url: "api/call/v2/manual_scorecard_names",
  method: "GET"
};

export const manual_scorecard_template: Endpoint<
  Types.ManualScorecardTemplateIn,
  Types.ManualScorecardTemplateOut
> = {
  url: "api/call/v2/manual_scorecard_template",
  method: "POST"
};

export const manual_scorecard_template_upload: Endpoint<
  Types.ManualScorecardTemplateUploadIn,
  {}
> = {
  url: "api/call/v2/manual_scorecard_template_upload",
  method: "POST"
};

// UI config endpoints

export const ui_config: Endpoint<{}, Types.UIConfig> = {
  url: "api/call/v2/ui_config",
  method: "GET"
};

export const ui_config_update: Endpoint<Types.UIConfigUpdateIn, {}> = {
  url: "api/call/v2/ui_config_update",
  method: "POST"
};

// Dashboard endpoints

export const qa_score_over_time: Endpoint<
  Types.FiltersIn,
  Types.QaScoreBucket[]
> = {
  url: "api/call/v2/qa_score_over_time",
  method: "POST"
};

export const avg_qa_scores: Endpoint<Types.FiltersIn, Types.AvgQaScores> = {
  url: "api/call/v2/avg_qa_scores",
  method: "POST"
};

export const team_category_scores: Endpoint<
  Types.TeamProfileIn,
  Types.TeamCategoryScoresOut[]
> = {
  url: "api/call/v2/team_category_scores",
  method: "POST"
};

export const high_risk_agents: Endpoint<
  Types.FiltersIn,
  Types.HighRiskAgents[]
> = {
  url: "api/call/v2/high_risk_agents",
  method: "POST"
};

export const best_agents: Endpoint<Types.FiltersIn, Types.BestAgents[]> = {
  url: "api/call/v2/best_agents",
  method: "POST"
};

// Team endpoints

export const teams: Endpoint<{}, Types.Team[]> = {
  url: "api/call/v2/teams",
  method: "GET"
};

export const team_delete: Endpoint<Types.TeamDeleteIn, undefined> = {
  url: "api/call/v2/team_delete",
  method: "POST"
};

export const team_upsert: Endpoint<Types.TeamUpsertIn, undefined> = {
  url: "api/call/v2/team_upsert",
  method: "POST"
};

export const scorecard_questions: Endpoint<
  Types.SearchQuestionsIn,
  Types.ScorecardQuestion[]
> = {
  url: "api/call/v2/scorecard_questions",
  method: "POST"
};

// Search endpoints

export const search_agents: Endpoint<
  Types.SearchAgentsIn,
  Types.AgentDetail[]
> = {
  url: "api/call/v2/search_agents",
  method: "POST"
};

export const search_by_call_id: Endpoint<
  Types.SearchByCallIdIn,
  Types.SearchByCallIdOut
> = {
  url: "api/call/v2/search_by_call_id",
  method: "POST"
};

export const search_by_phone_number: Endpoint<
  Types.SearchByCustomerPhoneNoIn,
  Types.SearchByCustomerPhoneNoOut[]
> = {
  url: "api/call/v2/search_by_phone_number",
  method: "POST"
};

export const search_by_case_id: Endpoint<
  Types.SearchByCaseIdIn,
  Types.SearchByCallIdOut[]
> = {
  url: "api/call/v2/search_by_case_id",
  method: "POST"
};

export const search_by_customer_id: Endpoint<
  Types.SearchByCustomerIdIn,
  Types.SearchByCallIdOut[]
> = {
  url: "api/call/v2/search_by_customer_id",
  method: "POST"
};

// Team profile endpoints

export const team_detail: Endpoint<Types.TeamDetailIn, Types.TeamDetailOut> = {
  url: "api/call/v2/team_detail",
  method: "POST"
};

export const team_scorecard_performance: Endpoint<
  Types.TeamProfileIn,
  Types.TeamScorecardPerformanceOut[]
> = {
  url: "api/call/v2/team_scorecard_performance",
  method: "POST"
};

export const team_stats: Endpoint<Types.TeamProfileIn, Types.TeamStatsOut[]> = {
  url: "api/call/v2/team_stats",
  method: "POST"
};

export const team_table: Endpoint<Types.TeamTableIn, Types.TeamTableOut> = {
  url: "api/call/v2/team_table",
  method: "POST"
};

// Transcript search endpoints

export const transcript_search: Endpoint<
  Types.TranscriptSearchIn,
  Types.TranscriptSearchOut
> = {
  url: "api/call/v2/transcript_search",
  method: "POST"
};

export const transcript_search_count: Endpoint<
  Types.TranscriptSearchCountIn,
  number
> = {
  url: "api/call/v2/transcript_search_count",
  method: "POST"
};

// Agent performance meeting endpoints

export const agent_performance_meetings: Endpoint<
  Types.AgentPerformanceMeetingIn,
  Types.AgentPerformanceMeetingOut[]
> = {
  url: "api/call/v2/agent_performance_meetings",
  method: "POST"
};

export const performance_meeting_insert: Endpoint<
  Types.AgentPerformanceMeetingInsertIn,
  string
> = {
  url: "api/call/v2/agent_performance_meeting_insert",
  method: "POST"
};

export const performance_meeting_update: Endpoint<
  Types.AgentPerformanceMeetingUpdateIn,
  string
> = {
  url: "api/call/v2/agent_performance_meeting_update",
  method: "POST"
};

export const performance_meeting_delete: Endpoint<
  Types.AgentPerformanceMeetingDeleteIn,
  string
> = {
  url: "api/call/v2/agent_performance_meeting_delete",
  method: "POST"
};

// Call appeal endpoints

export const call_appeal_insert: Endpoint<Types.CallAppealInsertIn, {}> = {
  url: "api/call/v2/call_appeal_insert",
  method: "POST"
};

export const call_comment_insert: Endpoint<Types.CallCommentInsertIn, {}> = {
  url: "api/call/v2/call_comment_insert",
  method: "POST"
};

export const call_review_update: Endpoint<Types.CallReviewInsertIn, {}> = {
  url: "api/call/v2/call_review_update",
  method: "POST"
};

// Configurations Page

export const add_configuration_v1: Endpoint<
  Types.PipelineConfigInsertInV1,
  {}
> = {
  url: "api/call/v2/pipeline_config_insert_v1",
  method: "POST"
};

export const add_configuration_v2: Endpoint<PipelineConfigInsertInV2, {}> = {
  url: "api/call/v2/pipeline_config_insert_v2",
  method: "POST"
};

export const fetch_aisle_table: Endpoint<
  Types.AisleTableIn,
  Types.AisleTableOut
> = {
  url: "api/call/v2/pipeline_config/aisles",
  method: "POST"
};

export const fetch_scorecard_table: Endpoint<
  Types.ScorecardTableIn,
  Types.ScorecardTableOut
> = {
  url: "api/call/v2/pipeline_config/scorecards",
  method: "POST"
};

export const get_scorecard_history: Endpoint<
  Types.ScorecardHistoryIn,
  Types.ScorecardHistoryRow[]
> = {
  url: "api/call/v2/pipeline_config/history",
  method: "POST"
};

export const get_all_scorecards: Endpoint<
  {},
  Types.ScorecardLabelAndVersion[]
> = {
  url: "api/call/v2/pipeline_config/all_scorecards",
  method: "GET"
};

export const load_scorecard: Endpoint<
  Types.ScorecardBuilderImportIn,
  ScorecardBuilderImport
> = {
  url: "api/call/v2/pipeline_config/scorecard",
  method: "POST"
};

export const rescore_by_call_ids: Endpoint<
  Types.FrontendRescoreByCallIdsIn,
  {}
> = {
  url: "api/call/v2/rescore/id",
  method: "POST"
};

export const rescore_by_table: Endpoint<Types.FrontendRescoreByTableIn, {}> = {
  url: "api/call/v2/rescore/table",
  method: "POST"
};

export const rescore_count_by_call_ids: Endpoint<
  Types.FrontendRescoreCountByCallIdsIn,
  Types.RescoreCountOut
> = {
  url: "api/call/v2/rescore_count/id",
  method: "POST"
};

export const rescore_count_by_table: Endpoint<
  Types.FrontendRescoreCountByTableIn,
  Types.RescoreCountOut
> = {
  url: "api/call/v2/rescore_count/table",
  method: "POST"
};

export const create_aisle: Endpoint<Types.CreateAisleIn, {}> = {
  url: "api/call/v2/pipeline_config/aisle_create",
  method: "POST"
};

export const update_aisle: Endpoint<Types.UpdateAisleIn, {}> = {
  url: "api/call/v2/pipeline_config/aisle_update",
  method: "POST"
};

export const get_queues: Endpoint<{}, string[]> = {
  url: "api/call/v2/queues",
  method: "GET"
};

export const download_legacy_config: Endpoint<
  Types.LegacyPipelineConfigIn,
  object
> = {
  url: "api/call/v2/pipeline_config/legacy",
  method: "POST"
};

// Quicksights

export const quicksight_dashboard: Endpoint<{}, string> = {
  url: "api/call/v2/dashboard",
  method: "GET"
};

export const log_user_event: Endpoint<Types.LogUserEventIn, {}> = {
  url: "api/call/v2/log_user_event",
  method: "POST"
};

// Phrase Library Endpoints

export const phrase_libraries_fetch: Endpoint<
  Types.PhraseLibrariesLatestIn,
  Types.PhraseLibrariesLatest
> = {
  url: "api/call/v2/phrase_library/latest",
  method: "POST"
};

export const phrase_libraries_history: Endpoint<
  Types.PhraseLibraryHistoryIn,
  Types.PhraseLibraryMetadata[]
> = {
  url: "api/call/v2/phrase_library/history",
  method: "POST"
};

export const phrase_library_insert: Endpoint<Types.PhraseLibraryInsertIn, {}> =
  {
    url: "api/call/v2/phrase_library/upload",
    method: "POST"
  };

export const phrase_library_download: Endpoint<
  Types.PhraseLibraryDownloadIn,
  Types.PhraseLibrary
> = {
  url: "api/call/v2/phrase_library/download",
  method: "POST"
};

export const phrase_library_groups: Endpoint<
  Types.PhraseLibrariesGroupsIn,
  Types.PhraseLibraryWithGroupLabels[]
> = {
  url: "api/call/v2/phrase_library/groups",
  method: "POST"
};

export const phrase_library_loader: Endpoint<
  {},
  Types.PhraseLibraryWithVersions[]
> = {
  url: "api/call/v2/phrase_library/loader",
  method: "GET"
};

// Removed calls endpoint

export const removed_calls: Endpoint<Types.RemovedCallsIn, Types.RemovedCalls> =
  {
    url: "api/call/v2/removed_calls",
    method: "POST"
  };

// Transcription config

export const transcription_config: Endpoint<{}, Types.TranscriptionConfig> = {
  url: "api/call/v2/transcription_config",
  method: "GET"
};

export const update_transcription_config: Endpoint<
  Types.TranscriptionConfig,
  {}
> = {
  url: "api/call/v2/transcription_config/update",
  method: "POST"
};

// Call navigation

export const call_navigation: Endpoint<
  Types.CallNavigationIn,
  Partial<Types.CallNavigation>
> = {
  url: "api/call/v2/call_navigation",
  method: "POST"
};

// Scorecard override

export const override_insert: Endpoint<
  Types.OverrideInsertIn,
  Types.OverrideInsertOut
> = {
  url: "api/call/v2/override",
  method: "POST"
};

export const override_delete: Endpoint<Types.OverrideDeleteIn, undefined> = {
  url: "api/call/v2/override/destroy",
  method: "POST"
};
