export default {
  filter: "filter",
  expand: "Expand All",
  collapse: "Collapse All",
  separator: "|",
  apply: "Apply",
  reset: "Reset",
  none: "none",
  risk: "high risk",
  highRisk: "high risk",
  notHighRisk: "not high risk",
  direction: "direction",
  agent: "{speaker}",
  selectAgents: "Select {agent}s",
  selectedAgentCount: `{count} Selected`,
  selectTeams: "Select Teams",
  selectedTeamCount: `{count} Selected`,
  allCalls: "all calls",
  inbound: "inbound",
  outbound: "outbound",
  agentChip: `{count, plural,
    one {{agent}}
    other {{count} Agents Selected}
  }`,
  teamChip: `{count, plural,
    one {{team}}
    other {{count} Teams Selected}
  }`,
  questions: "questions",
  questionChip: `{count} {count, plural,
    one {Question}
    other {Questions}
  } Selected`,
  searchForQuestions: "Search for Questions",
  teams: "Daisee Teams",
  teamValues: `{value, select,
    allTeams {All Teams}
    other {{value}}
  }`,
  callLabelsChip: `{count, plural,
    one {{count} Label}
    other {{count} Labels}
  }`,
  allTeams: "All Teams",
  directionOptions: `{value, select,
    default {All Calls}
    other {{value}}
  }`,
  riskOptions: `{value, select,
    other {All Calls}
    notHighRisk {not high risk}
    highRisk {high risk}
  }`,
  speakerOptions: `{value, select,
    default {Both}
    other {{value}}
  }`,
  reviewStatus: "review status",
  reviewStatusOptions: `{value, select,
    default {All Calls}
    REQUIRES_ACTION {Requires Action}
    COMPLETE {Complete}
    other {All Calls}
  }`,
  REQUIRES_ACTION: "Requires Action",
  COMPLETE: "Complete",
  layers: "Layers",
  allLayers: "All Layers",
  both: "both",
  processingStatus: "processing status",
  processingStatusOptions: `{value, select,
    done {Done}
    processing {Processing}
    failed {Failed}
    other {All Calls}
  }`,
  done: "Done",
  processing: "Processing",
  failed: "Failed",
  exclusionScorecard: "auto-exclusion scorecard applied",
  exclusionScorecardOptions: `{value, select,
    other {All Calls}
    applied {applied}
    not_applied {not applied}
  }`,
  exclusionScorecardChip: `{value, select,
  other {All Calls}
  applied {Exclusion Scorecards}
  not_applied {Non-Exclusion Scorecards}
}`,
  applied: "applied",
  not_applied: "not applied",
  // Custom data
  customData: "Custom Data",
  customDataKey: "Name",
  customDataValue: "Value",
  callLabels: "Call Labels",
  noLabel: "No label",

  overriddenScorecard: "scorecard override applied",
  overriddenScorecardOptions: `{value, select,
    other {All Calls}
    applied {applied}
    not_applied {not applied}
  }`,
  overriddenScorecardChip: `{value, select,
  other {All Calls}
  applied {Overridden Scorecards}
  not_applied {Non-Overridden Scorecards}
}`,
  // Call length

  callLength: "Call Length",
  callLengthChip: "Call Length filter selected",
  // Excessive Silences
  silences: "silences",
  longestSilence: "Longest Silence Duration",
  totalSilence: "Total Silence Duration",
  percentageSilence: "Silence Percentage",
  silenceChip: `{count} {count, plural,
    one {Silence Filter}
    other {Silence Filters}
  } Selected`,
  percentageSilenceMin: "{minVal}%",
  percentageSilenceMax: "{maxVal}%",

  // Call Scores
  scores: "scores",
  scoreOverall: "QA Score",
  scoreCommunication: "Communication Score",
  scoreCompliance: "Compliance Score",
  scoreConduct: "Conduct Score",
  scoreChip: `{count} {count, plural,
    one {Score Filter}
    other {Score Filters}
  } Selected`,

  // Call Appeal
  exclusionReasons: "Excluded From QA Aggregate Score",
  selectReasons: "Select Reasons",
  exclusionReasonsChip: `{count, plural,
    one {{count} Exclusion Reason}
    other {{count} Exclusion Reasons}
  }`,

  addFilter: "Add Filter",

  selectValues: "Select values",
  selectedValuesCount: `{count} Selected`,

  selectAll: "Select all",
  deselectAll: "Deselect all"
};
