import Switch from "./Switch";
import Layout from "../layouts";

import { History } from "history";
import { mainRoutes } from "./routes";
import { ComponentType } from "react";
import { Router } from "react-router-dom";
import { RouterProps } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { userRolesKey } from "lib/visibility";

export interface Props {
  readonly history: History;
  readonly routerComponent?: ComponentType<RouterProps>;
}

const Routes = ({ history, routerComponent: Router_ = Router }: Props) => {
  const { user } = useAuth0();
  const userRoles = user?.["https://daisee.com/app_metadata"]?.roles;

  if (userRoles) {
    sessionStorage.setItem(userRolesKey, JSON.stringify(userRoles));
  }

  return (
    <Router_ history={history}>
      <Layout>
        <Switch routes={mainRoutes} />
      </Layout>
    </Router_>
  );
};

export default Routes;
