import callTableHeaderMessages from "components/Table/en";
import callDirectionMessages from "components/DirectionFilter/en";
import dateRangePickerMessages from "components/DateRangePicker/en";

import { messages } from "components/I18/en";

export default {
  ...messages,
  ...callDirectionMessages,
  ...dateRangePickerMessages,
  ...callTableHeaderMessages,

  daisee: "daisee",

  // Analytic Navs
  analytics: "analytics",
  dashboard: "dashboard",
  allCalls: "all calls",
  callEssence: "call essence",
  teamReports: "team reports",

  // Insights
  insights: "insights",
  essence: "essence",
  predictors: "predictors",
  usage: "usage",
  reportBuilder: "report builder",
  rawData: "raw data",
  callerSatisfaction: "caller satisfaction",
  demoDashboard: "demo",
  chat: "chat",
  recoveryRisk: "recovery risk",
  firstCallResolution: "first call resolution",
  actionableInsights: "actionable insights",
  essenceV2: "essence v2",

  // Search Navs
  search: "search",
  transcriptSearch: "transcript search",
  searchCalls: "search calls",
  searchPhoneNo: "search by phone number",
  searchAgents: "search {speaker}s",
  searchCaseId: "search by case ID",
  searchCustomerId: "search by customer ID",

  // Admin Routes
  administration: "administration",
  callProcessing: "call processing",
  manageTeams: "manage teams",
  configurations: "configurations",
  phraseLibrary: "phrase library",
  callLabels: "call labels",
  uiConfigMgmt: "UI Config Management",
  manualScorecardUpload: "Manual Scorecards",
  transcriptionConfig: "transcription config",
  removedCalls: "removed calls",

  // Footer
  switchToChat: "switch to chat",
  support: "support",
  logout: "logout",

  // Client Info popup
  userInfo:
    "Client: {client} {br} Environment: {environment} {br} Roles: {roles}"
};
