export const messages: Record<string, string> = {
  weekly: "1W",
  fortnightly: "2W",
  monthly: "1M",
  quarterly: "3M",
  halfYearly: "6M",
  yearly: "1Y"
};
export default messages;

export const dateRangePickerRescoreMessages: Record<string, string> = {
  weekly: "7d",
  fortnightly: "14D",
  monthly: "30D",
  fortyFive: "45D",
  maxDays: "Max Rescore"
};
