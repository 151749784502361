import { Endpoint } from "../generated/tsdefApi";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";

import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseQueryOptions,
  UseMutationResult,
  UseMutationOptions
} from "react-query";

interface OutResponse<Out> extends Response {
  json(): Promise<Out>;
}

interface ErrorResponse {
  message: string;
  description?: string;
}

export class EndpointError extends Error {
  statusCode?: number;
  description?: string;

  constructor(message: string, code?: number, description?: string) {
    super(message);
    this.statusCode = code;
    this.description = description;
    this.name = "EndpointError";
  }
}

export const useEndpoint = <In, Out>(
  endpoint: Endpoint<In, Out>,
  variables: In,
  auth0: Auth0ContextInterface<User>,
  options?: UseQueryOptions<Out, EndpointError>,
  queryParams?: Record<string, string>
): UseQueryResult<Out, EndpointError> =>
  useQuery<Out, EndpointError>({
    ...options,
    queryKey: [endpoint.url, variables],
    queryFn: async () => {
      const response = await fetchEndpoint(
        endpoint,
        variables,
        auth0,
        queryParams
      ).catch(e => {
        throw new EndpointError((e as Error).message);
      });
      if (!response.ok) {
        throw new EndpointError("Network response was not ok", response.status);
      }
      return response.json().catch(e => {
        throw new EndpointError((e as Error).message);
      });
    }
  });

export const useEndpointMutation = <In, Out>(
  endpoint: Endpoint<In, Out>,
  auth0: Auth0ContextInterface<User>,
  options?: UseMutationOptions<Out, EndpointError, In, unknown>
): UseMutationResult<Out, EndpointError, In, unknown> => {
  return useMutation({
    ...options,
    mutationKey: endpoint.url,
    mutationFn: async (variables: In) => {
      const response = await fetchEndpoint(endpoint, variables, auth0).catch(
        e => {
          throw new EndpointError((e as Error).message);
        }
      );
      if (!response.ok) {
        const contentType = response.headers.get("Content-Type");
        let errorMessage = "Network response was not ok";
        let errorDescription = "No additional error info provided.";

        try {
          if (contentType && contentType.includes("application/json")) {
            // Handle json error response (which currently doesn't really exist)
            const errorBody = (await response.json()) as ErrorResponse;
            errorMessage = errorBody.message || errorMessage;
            errorDescription = errorBody.description || errorDescription;
          } else {
            // Handle plain text error response
            errorMessage = await response.text();
          }
          throw new EndpointError(
            errorMessage,
            response.status,
            errorDescription
          );
        } catch (e) {
          if (e instanceof Error) {
            throw new EndpointError(
              e.message,
              response.status,
              "Error parsing response body"
            );
          } else {
            throw new EndpointError(
              "Unknown error during parsing",
              response.status,
              "Unknown parsing error"
            );
          }
        }
      }
      return response.json().catch(e => {
        throw new EndpointError((e as Error).message);
      });
    }
  });
};

export const fetchEndpoint = async <In, Out>(
  endpoint: Endpoint<In, Out>,
  variables: In,
  { getAccessTokenSilently }: Auth0ContextInterface<User>,
  queryParams?: Record<string, string>
): Promise<OutResponse<Out>> => {
  const token = await getAccessTokenSilently({
    authorizationParams: {
      audience: "https://daisee.com/"
    }
  });
  const bearerToken = `Bearer ${token}`;
  const post =
    endpoint.method === "POST"
      ? {
          headers: {
            "Content-Type": "application/json",
            Authorization: bearerToken
          },
          body: JSON.stringify(variables)
        }
      : {
          headers: {
            "Content-Type": "application/json",
            Authorization: bearerToken
          }
        };
  return await fetch(buildURLQueryParams(endpoint, queryParams), {
    ...post,
    method: endpoint.method,
    credentials: "same-origin",
    mode: "same-origin"
  });
};

const buildURLQueryParams = <In, Out>(
  endpoint: Endpoint<In, Out>,
  queryParams?: Record<string, string>
) =>
  (
    endpoint.url +
    "?" +
    Object.entries(queryParams || {}).map(r => `${r[0]}=${r[1]}&`)
  ).slice(0, -1);
