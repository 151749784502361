import speakerMessages from "../SpeakerLabel/en";
import filterMessages from "Voice/components/Filter/en";
import clipboardMessages from "components/Clipboard/en";

export default {
  ...filterMessages,
  ...speakerMessages,
  ...clipboardMessages,

  value: "{value}",
  headingAction: "Actions",
  headingCallID: "Call ID",
  headingSummary: "Summary",
  headingDaiseeID: "Daisee ID",
  headingRecorded: "Recorded At",
  headingDuration: "Duration",
  headingCommunication: "Communication",
  headingCompliance: "Compliance",
  headingConduct: "Conduct",
  headingQAScore: "QA Score",
  headingCall: "Call",
  headingProcessingStatus: "Status",
  headingStarted: "Started",
  headingFinished: "Finished",
  headingSize: "Size",
  headingReviewCall: "Review Status",
  headingViewCall: "Play",
  headingSearchResults: "Relevancy",
  headingDescription: "Description",
  headingRemovedBy: "Removed By",
  headingRemovedAt: "Removed At",
  headingExternalReference: "External Reference",
  cellAgent: "{agentName}",
  cellCallID: "{callID}",
  cellStatus: "{status}",
  cellSize: "{size}",
  cellViewCall: "View",
  cellSearchResults: "{count} %",
  notAvailable: "N/A",
  totalRows: "Total rows:",
  page: "Page",
  pageSizeOptions: "{option} rows",
  of: "of",
  previous: "previous",
  next: "next",
  calculatingPages: "of calculating...",
  rowOptions: "{option} rows",
  callCount: "call count",
  callCountResult: "{count}",
  highRiskCallCount: "high risk count",
  text: "{text}",
  noRowsFound: "No rows found",
  previousPage: "previous",
  nextPage: "Next",
  pageCount: "of {pageCount}",
  rowCount: "Total Rows: {totalCount}"
};
