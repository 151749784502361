import callTableHeaderMessages from "components/Table/en";
import callDirectionMessages from "components/DirectionFilter/en";
import dateRangePickerMessages from "components/DateRangePicker/en";

import { messages } from "components/I18/en";

export default {
  ...messages,
  ...callDirectionMessages,
  ...dateRangePickerMessages,
  ...callTableHeaderMessages,

  daisee: "daisee",

  // Analytic Navs
  analytics: "analytics",
  profile: "profile",

  // Search Navs
  search: "search",
  transcriptSearch: "transcript search",
  searchCalls: "search calls",
  searchCaseId: "search by case ID",
  searchCustomerId: "search by customer ID",

  // Footer
  support: "support",
  logout: "logout"
};
