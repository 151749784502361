import {
  useState,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren
} from "react";

import { merge } from "lodash";
import { ui_config } from "lib/tsdefApiV2";
import { useAuth0 } from "@auth0/auth0-react";
import { UIConfig } from "@daiseeai/call-backend-types";
import { useEndpoint } from "lib/useEndpoint";

interface SetConfigFn {
  (config: UIConfig): void;
}

interface ContextProps {
  readonly config: UIConfig;
  readonly setConfig: SetConfigFn;
}

const defaultConfig: UIConfig = {
  uIConfigAnalytics: {
    analyticsType: "None",
    analyticsSiteId: null,
    analyticsAppId: null
  },
  uIConfigCallAppeal: {
    crudReadPermission: "VDaiseeEmployee",
    crudEditPermission: "VDaiseeEmployee",
    crudCreatePermission: "VDaiseeEmployee",
    crudDeletePermission: "VDaiseeEmployee"
  },
  uIConfigCallAppealDescriptionLength: 0,
  uIConfigCallDrivers: "VHidden",
  uIConfigCallLabels: {
    crudReadPermission: "VDaiseeEmployee",
    crudEditPermission: "VDaiseeEmployee",
    crudCreatePermission: "VDaiseeEmployee",
    crudDeletePermission: "VDaiseeEmployee"
  },
  uIConfigManualScorecard: {
    manualScorecardDisplayWeight: true,
    manualScorecardPermissions: {
      crudReadPermission: "VDaiseeEmployee",
      crudEditPermission: "VDaiseeEmployee",
      crudCreatePermission: "VDaiseeEmployee",
      crudDeletePermission: "VDaiseeEmployee"
    }
  },
  uIConfigCustomMetadata: {
    customMetadataView: "VEveryone",
    customMetadataFilter: "VCustomerAdmin"
  },
  uIConfigDownloads: "VDaiseeEmployee",
  uIConfigExternalId: {
    externalIdMatch: "",
    externalIdReplace: ""
  },
  uIConfigHideScorecardForAgent: false,
  uIConfigLocale: "en-AU",
  uIConfigRest: {
    restUri: "/api/call/v2",
    restAudioPath: ""
  },
  uIConfigSearch: {
    searchGrouping: true,
    searchCustomer: true
  },
  uIConfigSpeakerLabels: {
    speakerLabelsAgent: "agent",
    speakerLabelsCaller: "caller"
  },
  uIConfigTeamManagement: {
    crudReadPermission: "VDaiseeEmployee",
    crudEditPermission: "VDaiseeEmployee",
    crudCreatePermission: "VDaiseeEmployee",
    crudDeletePermission: "VDaiseeEmployee"
  },
  uIConfigVersion: 0,
  uIConfigInsights: {
    insightsEssence: "VDaiseeEmployee",
    insightsPredictors: "VDaiseeEmployee",
    insightsUsage: "VDaiseeEmployee",
    insightsRawData: "VDaiseeEmployee",
    insightsCallerSatisfaction: "VDaiseeEmployee",
    insightsDemoDashboard: "VDaiseeEmployee",
    insightsChat: "VDaiseeEmployee",
    insightsRecoveryRisk: "VDaiseeEmployee",
    insightsFirstCallResolution: "VDaiseeEmployee",
    insightsActionableInsights: "VDaiseeEmployee",
    insightsEssenceV2: "VDaiseeEmployee"
  },
  uIConfigCallProcessing: "VDaiseeEmployee",
  uIConfigWhiteLabel: "Default",
  uIConfigScorecardManagement: {
    crudReadPermission: "VDaiseeEmployee",
    crudEditPermission: "VDaiseeEmployee",
    crudCreatePermission: "VDaiseeEmployee",
    crudDeletePermission: "VDaiseeEmployee"
  },
  uIConfigChat: {
    chatConfigVisibility: "VDaiseeEmployee"
  },
  uIConfigManualScorecardManagement: "VDaiseeEmployee",
  uIConfigScorecardOverride: {
    crudReadPermission: "VDaiseeEmployee",
    crudEditPermission: "VDaiseeEmployee",
    crudCreatePermission: "VDaiseeEmployee",
    crudDeletePermission: "VDaiseeEmployee"
  }
};

export const Context: React.Context<ContextProps> = createContext({
  config: defaultConfig,
  setConfig: (_: UIConfig) => {}
});

export const useConfig = () => {
  const { config } = useContext(Context);
  return config;
};

const setConfigWithDefaults =
  (setter: SetConfigFn): SetConfigFn =>
  config =>
    setter(merge({}, defaultConfig, config));

// It is necessary to split the loading component out else we get some diffcult
// to resolve issue with mutating state and Provider when testing
export const Provider = ({ children }: PropsWithChildren<{}>) => {
  const [config, setter] = useState(defaultConfig);
  const setConfig = setConfigWithDefaults(setter);

  const auth0 = useAuth0();
  const { data, refetch } = useEndpoint(ui_config, {}, auth0, {
    enabled: false
  });

  useEffect(() => {
    auth0.isAuthenticated && refetch();
  }, [auth0.isAuthenticated]);

  useEffect(() => {
    if (data) {
      setConfig(data);
    }
  }, [data]);

  return (
    <Context.Provider value={{ config, setConfig }}>
      {children}
    </Context.Provider>
  );
};
