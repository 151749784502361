import { Visibility } from "@daiseeai/call-backend-types";

export const userRolesKey = "currentUserRoles";

const getUserRoles = (): string[] => {
  const unparsedRoles: string | null | undefined =
    sessionStorage.getItem(userRolesKey);

  if (!!unparsedRoles) {
    try {
      return JSON.parse(unparsedRoles || "[]");
    } catch {
      console.warn("Unable to parse current user groups");
      return [];
    }
  } else {
    return [];
  }
};

export const agentRole = "ClientAgent";
export const clientUser = "ClientUser";
export const clientCoach = "ClientCoach";
export const clientAdminRole = "ClientAdmin";
export const daiseeEmployeeRole = "DaiseeAdmin";
const downloadRole = "ClientDownload";
const clientScorecardBuilderRole = "ClientBuilder";

export const evalVisibility = (visibility: Visibility): boolean => {
  const userRoles = getUserRoles();

  switch (visibility) {
    case "VEveryone":
      return true;
    case "VHidden":
      return false;
    case "VGeneralUser":
      return (
        userRoles.includes(clientUser) ||
        userRoles.includes(clientCoach) ||
        userRoles.includes(clientAdminRole) ||
        userRoles.includes(daiseeEmployeeRole)
      );
    case "VCustomerCoach":
      return (
        userRoles.includes(clientCoach) ||
        userRoles.includes(clientAdminRole) ||
        userRoles.includes(daiseeEmployeeRole)
      );
    case "VCustomerAdmin":
      return (
        userRoles.includes(clientAdminRole) ||
        userRoles.includes(daiseeEmployeeRole)
      );
    case "VDaiseeEmployee":
      return userRoles.includes(daiseeEmployeeRole);
    case "VAgent":
      return userRoles.includes(agentRole);
    case "VDownload":
      return (
        userRoles.includes(downloadRole) ||
        userRoles.includes(daiseeEmployeeRole)
      );
    case "VClientScorecardBuilder":
      return (
        userRoles.includes(clientScorecardBuilderRole) ||
        userRoles.includes(daiseeEmployeeRole)
      );
  }
};
