const primary = "#2e60f4";
const muted = "#6c757d";
const good = "#01b78f";
const bad = "#fb323a";

export default {
  /* Main Colours */
  primary,
  secondary: "#0454ac",
  muted: "#6c757d",
  background: "#f2f4f9",
  text: "#1d253b",
  textLight: "#9a9a9a",

  /* Scoring Colours */
  good,
  neutral: " #a3a3a3",
  bad,

  /* Badges */
  positive: "#e8f5e7",
  promote: "#ebe6ee",
  info: "#e8ecf2",
  objective: "#eaebed",
  caution: "#fcf6e1",
  critical: "#f4e4e6",

  /* Blues */
  blue: primary,
  blue500: "#3358f4",
  blue400: "#1d8cf8",
  blue300: "#678bf7",
  blue200: "#8da8f9",
  blue100: "#b3c5fb",

  /* Purples */
  purple: "#c44fe9",
  purple300: "#d689f0",
  purple200: "#e4aff5",
  purple100: "#eecff9",

  /* Orange */
  orange: "#f39834",
  orange300: "#f6b46b",
  orange200: "#f8c690",
  orange100: "#fad9b5",

  /* Pinks */
  pink: "#fd5288",
  pink300: "#fd81a8",
  pink200: "#fda0be",
  pink100: "#fec0d3",

  /* Green */
  green: good,
  green100: "#16df04",
  green200: "#10b701",
  green300: "#0e9b01",
  green400: "#0e8802",

  /* Yellow */
  yellow: "#ffff99",
  yellow100: "#f0e68c",
  yellow200: "#ecdd52",
  yellow300: "#e6d11c",

  /* Grays */
  gray100: "#f6f6f6",
  gray200: "#e9ecef",
  gray300: "#e3e3e3",
  gray400: "#ced4da",
  gray500: "#adb5bd",
  gray600: muted,
  gray700: "#525f7f",
  gray800: "#32325d",
  gray900: "#212529",

  /* Red */
  red: bad,
  red100: "#dc040d"
};
