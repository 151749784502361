import "moment/locale/en-au";
import "moment/locale/en-ca";
import "moment/locale/en-gb";
import "moment/locale/en-ie";
import "moment/locale/en-il";
import "moment/locale/en-nz";
import "moment/locale/en-sg";

import searchMessages from "../Search/en";
import errorMessages from "components/Errored/en";

const messages: Record<string, string> = {
  ...errorMessages,
  ...searchMessages,
  unknown: "unknown",
  value: "{value}",

  // Footer
  support: "support",
  logout: "logout",

  // Client Info popup
  userInfo:
    "Client: {client} {br} Environment: {environment} {br} Roles: {roles}"
};

export { messages };
