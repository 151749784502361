import { lazy } from "react";
import { Language } from "components/I18";
import { BaseRoute, Route } from "./Switch";

import chatMessages from "Chat/en";
import voiceMessages from "Voice/en";
import noRolesMessages from "NoRoles/en";
import agentViewMessages from "AgentView/en";
import authErrorMessages from "AuthError/en";

const Chat = lazy(() => import("Chat/App"));
const Voice = lazy(() => import("Voice/App"));
const NoRoles = lazy(() => import("NoRoles/App"));
const AgentView = lazy(() => import("AgentView/App"));
const AuthError = lazy(() => import("AuthError/App"));

export const voice: Route = {
  path: "/voice",
  name: "switchToVoice",
  redirectFrom: ["/"],
  messages: {
    [Language.EN]: voiceMessages
  },
  render: props => <Voice {...props} />
};

export const chat: Route = {
  path: "/chat",
  name: "switchToChat",
  messages: {
    [Language.EN]: chatMessages
  },
  render: props => <Chat {...props} />
};

const agentView = {
  path: "/agentView",
  name: "agentView",
  messages: {
    [Language.EN]: agentViewMessages
  },
  render: () => <AgentView />
};

const noRoles = {
  path: "/noRoles",
  name: "noRoles",
  messages: {
    [Language.EN]: noRolesMessages
  },
  render: () => <NoRoles />
};

const authError = {
  path: "/authError",
  name: "authError",
  messages: {
    [Language.EN]: authErrorMessages
  },
  render: () => <AuthError />
};

export const mainRoutes: BaseRoute[] = [
  voice,
  chat,
  agentView,
  noRoles,
  authError
];
