import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { CacheLocation } from "@auth0/auth0-spa-js";

import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions
} from "@auth0/auth0-react";

interface Props {
  children: ReactNode;
}

export const AuthWrapper = ({ children }: Props) => {
  const cacheLocation: CacheLocation = "localstorage";
  const history = useHistory();

  const onRedirectCallback = (appState: AppState | undefined) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  const options: Auth0ProviderOptions = {
    domain: window._env_.AUTH0_SECRET_DOMAIN,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: window._env_.AUTH0_SECRET_AUDIENCE
    },
    clientId: window._env_.AUTH0_SECRET_CLIENTID,
    cacheLocation: cacheLocation,
    useRefreshTokens: true,
    onRedirectCallback
  };

  return <Auth0Provider {...options}>{children}</Auth0Provider>;
};
