import styled, { keyframes } from "styled-components/macro";

import useComponentSize from "@rehooks/component-size";

import { useRef } from "react";

interface Props {
  size: number;
}

export default () => {
  const ref = useRef<HTMLDivElement>(null);
  const { width, height } = useComponentSize(ref);
  const size = Math.max(Math.min(width, height, 100), 50);

  return (
    <SpinnerWrapper ref={ref}>
      <Spinner size={size} />
    </SpinnerWrapper>
  );
};

const rotate = keyframes`
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Spinner = styled.div<Props>`
  border: 4px solid ${({ theme }) => theme?.colours?.gray500};
  border-top: 4px solid ${({ theme }) => theme?.colours?.gray900};
  border-radius: 50%;
  animation: ${rotate} 0.6s infinite linear;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin: 0 auto;
`;
